import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  onAuthStateChanged,
  signOut,
  updateProfile,
  sendEmailVerification
} from 'firebase/auth'
import type { RegisterDetails, UserDetails, UserDetailsBase } from '~/types/user'

export function useAuth() {
  // notifications
  const { addToast } = useToastStore()
  // import auth and app
  // $i18n is used directly, because i18n doesn't like our auth-middleware
  const { $auth, $i18n } = useNuxtApp()

  // Toast notifications
  // const { addToast } = useToastStore()

  // Initialize Firebase
  const auth = $auth
  const user = ref(auth.currentUser)
  const loading = ref(false)
  const error = ref<string | null>(null)

  onAuthStateChanged(auth, (currentUser) => {
    user.value = currentUser
  })

  // beforeAuthStateChanged(auth, (currentUser) => {
  //   if (!currentUser?.emailVerified) {
  //     signOut(auth)
  //     navigateTo('/')
  //   }
  // })

  const login = async (email: string, password: string): Promise<boolean> => {
    const { getUserDetailsWithSubcollections } = useFirebase()
    loading.value = true
    error.value = null

    // buttonState.value = 'loading'
    return await signInWithEmailAndPassword(auth, email, password)
      .then(async (user) => {
        console.log(user)
        if (!user.user.emailVerified) {
          error.value = 'checkEmailAndPassword'
          signOut(auth)
          return false
        }
        else {
          const userDetails: UserDetails | null = await getUserDetailsWithSubcollections()
          // if user details received, set details and redirect to ynteenveto page
          if (userDetails) {
            const userStore = useUserStore()
            userStore.setLoggedInStatus(true)
            userStore.setUserDetails((userDetails))
            return true
          }
          else {
            error.value = 'errorOnLoginTryAgain'
            return false
          }
        }
      })
      .catch(() => {
        error.value = $i18n.t('checkEmailAndPassword')
        addToast({ message: error.value || $i18n.t('error'), type: 'error', ttl: 5000 })
        return false
      }).finally(() => {
        loading.value = false
      })
  }

  const createAccount = async (registerDetails: RegisterDetails) => {
    loading.value = true
    error.value = null
    try {
      return await createUserWithEmailAndPassword(auth, registerDetails.email, registerDetails.password)
        .then(async (response: any) => {
          // Update Auth user with display name
          await updateProfile(response.user, {
            displayName: registerDetails.firstname
          })
            .catch((err: any) => {
              console.error(err)
              throw new Error(err)
              // Sentry.captureException(err)
            })

          const createdAt = new Date()
          // remove password from data
          const userDetails: UserDetailsBase = {
            firstname: registerDetails.firstname,
            lastname: registerDetails.lastname,
            email: registerDetails.email,
            registerEmail: registerDetails.email,
            phone: registerDetails.phone || null,
            dob: null,
            createdAt: createdAt.toISOString(), // $fireModule.firestore.FieldValue.serverTimestamp() as $fireModule.firestore.Timestamp,
            marketingPermissions: {
              sms: false,
              email: false
            }
          }

          // send email verification
          await sendEmailVerification(response.user)

          // create user details node
          const { createUserDocument } = useFirebase()
          return await createUserDocument(response.user.uid, userDetails)
            .then(() => {
              return true
            })
            .catch((err: any) => {
              // Sentry.captureException(err)
              console.error('Creating user document failed', err)
              error.value = err.message
              return false
            })
        })
        /**
         * Creating new GCP Auth-entry failed
         */
        .catch((err: any) => {
          switch (err.code) {
            case 'auth/email-already-in-use':
              error.value = 'Sähköpostiosoite on jo käytössä.'
              break
            case 'auth/invalid-email':
              error.value = 'Sähköpostiosoite on väärässä formaatissa.'
              break
            case 'auth/network-request-failed':
              error.value = 'Yhteysongelma havaittu. Tarkista internetyhteytesi.'
              break
            default:
              // Sentry.captureException(err)
              error.value = err.message
          }
          addToast({ message: error.value || $i18n.t('error'), type: 'error', ttl: 5000 })
          return false
        })
        .finally(() => {
          signOut(auth)
        })
    }
    catch (err) {
      error.value = (err as Error).message
    }
    finally {
      loading.value = false
    }
  }

  const forgotPassword = async (email: string) => {
    loading.value = true
    error.value = null
    try {
      await sendPasswordResetEmail(auth, email)
    }
    catch (err) {
      error.value = (err as Error).message
    }
    finally {
      loading.value = false
    }
  }

  const logout = async () => {
    loading.value = true
    error.value = null
    try {
      await signOut(auth)
      const userStore = useUserStore()
      userStore.$reset()
      addToast({ message: $i18n.t('loggedOut'), type: 'success', ttl: 5000 })
      await navigateTo('/')
    }
    catch (err) {
      error.value = (err as Error).message
    }
    finally {
      loading.value = false
    }
  }

  return {
    user,
    loading,
    error,
    login,
    createAccount,
    forgotPassword,
    logout,
    onAuthStateChanged,
    auth
  }
}
